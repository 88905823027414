import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'

// components
import Button from '~/components/form/Button';
import MsgResponse from '~/components/MsgResponse'
import UnfinishedClass from './UnfinishedClass'

// rest
import { useGet, usePut } from '~/services/rest'

// style
import { WrapFinalButtons } from './style'
import { color } from '~/styles/variables'

// functions
import { stripSlashes } from '~/services/functions'

const NextButton = ({ classData, handleClassContent, setUpdateCourseSidebar }) => {

  // faz requisição dos módulos e aulas para comparar e ver qual próxima aula
  const userCourse = useGet(classData ? `historicocursos/${classData.historico_modulos.historico_cursos_id}` : false)
  const [courseData, setCourseData] = useState()

  useEffect(() => {
    if (userCourse.data.length > 0) {
      setCourseData(userCourse.data)
    }
  }, [userCourse])

  // atualiza aula atual
  const [, setChangeStatus] = usePut(`historicoaulas/${classData && classData.idhistorico_aulas}`)

  const [nextClass, setNextClass] = useState({})
  const [nextModule, setNextModule] = useState({})
  const [classesUnfinished, setClassesUnfinished] = useState([])

  // atualiza data de inicio da próxima aula
  const [, setChangeStatusNextClass] = usePut(`historicoaulas/${nextClass && nextClass.id}`)
  const [, setChangeStatusNextModule] = usePut(`historicoaulas/${nextModule && nextModule.id}`)

  // pega dados da próxima aula e próximo modulo
  // classData -> dados da aula atual
  // courseData -> dados do curso (curso/modulo/aulas) gerados

  useEffect(() => {

    let tmpNextClass = {}
    let tmpNextModuleId = {}
    let arrClassesUnfinished = []

    if (courseData && classData) {

      // pega o id e nome da próxima aula --------------------------------
      // controla qual o index da próxima aula
      let nextClassIndex = null
      let nextModuleIndex = null

      courseData[0].historico_modulos.forEach((itemModule, indexModule) => { // percorre array de módulos

        let objModuleUnfinished = {}

        if (itemModule.status !== 11) {
          objModuleUnfinished = {
            title: itemModule.modulos.titulo,
            class: []
          }
        }

        itemModule.historico_aulas.forEach((itemClass, indexClass) => { // percorre array de aulas

          if (itemClass.status !== 11) {
            if (objModuleUnfinished.class) objModuleUnfinished.class.push({ title: itemClass.aulas.titulo });
          }

          if (classData.historico_modulos_id === itemModule.idhistorico_modulos) { // verifica se o id da aula é igual do array
            if (classData.idhistorico_aulas === itemClass.idhistorico_aulas) { // verifica se o id da aula é igual do array
              nextClassIndex = indexClass + 1 // pega o index da próxima aula
            }
            if (nextClassIndex === indexClass) { // verifica se ja é a próxima aula e atribui seu conteúdo
              tmpNextClass = { id: itemClass.idhistorico_aulas, title: itemClass.aulas.titulo, iniciado_em: itemClass.iniciado_em }
              tmpNextModuleId = { id: itemModule.idhistorico_modulos, iniciado_em: itemModule.iniciado_em }
            }

            nextModuleIndex = indexModule + 1; // pega o próximo modulo caso seja a ultima aula do modulo atual
          }

          // verifica se o objeto da próxima aula esta vazio, se sim quer dizer que chegou na ultima aula do módulo
          // também verifica se é o próximo modulo
          if (Object.keys(tmpNextClass).length === 0 && nextModuleIndex === indexModule) {
            tmpNextClass = { id: itemClass.idhistorico_aulas, title: itemClass.aulas.titulo, iniciado_em: itemClass.iniciado_em }
            tmpNextModuleId = { id: itemModule.idhistorico_modulos, iniciado_em: itemModule.iniciado_em }
          }
        })

        if (Object.keys(objModuleUnfinished).length > 0) {
          arrClassesUnfinished.push(objModuleUnfinished)
        }
      })
    }

    setNextClass(tmpNextClass)
    setNextModule(tmpNextModuleId)
    setClassesUnfinished(arrClassesUnfinished)

  }, [courseData, classData])

  const handlerNextClass = async () => {

    const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")

    // se ja possuir data de conclusao só altera status
    if (!classData.concluido_em) {
      await setChangeStatus({
        status: 11,
        concluido_em: now
      })
    } else {
      await setChangeStatus({ status: 11, })
    }

    // insere data de inicio do modulo ou aula
    if (!nextClass.iniciado_em) {
      await setChangeStatusNextClass({ iniciado_em: now })
    }
    if (!nextModule.iniciado_em) {
      await setChangeStatusNextModule({ iniciado_em: now })
    }

    await setUpdateCourseSidebar(true)

    await handleClassContent(nextClass.id, nextModule.id)
  }

  const themeButtonAvaliation = {
    bg: 'tranpsarent',
    fc: color.second,
    bdc: 'tranpsarent',
    bdc_hover: color.second,
  }

  if (classData) {
    if (Object.keys(nextClass).length > 0) {
      return (
        <>
          <h3 className='fw-300 thirdColor'>Próximo: <strong>{stripSlashes(nextClass.title)}</strong></h3>
          <Button fullXs width='200px' onClick={() => handlerNextClass()}>Avançar</Button>
        </>
      )
    }
    console.log(userCourse.data);

    // só exibe botao de certificado e avaliação de curso na ultima aula
    // && (valida ultima aula do ultimo modulo)
    if (userCourse.data.length > 0 && (userCourse.data[0].historico_modulos[userCourse.data[0].historico_modulos.length - 1].historico_aulas[userCourse.data[0].historico_modulos[userCourse.data[0].historico_modulos.length - 1].historico_aulas.length - 1].aulas_id === classData.aulas_id)) {
      if (userCourse.data[0].status !== 11) {
        return (
          <div className="component-unfinished-class">
            <MsgResponse msg='Certifique-se de que todas aulas foram concluídas para obter o certificado!' theme='alert' />
            <UnfinishedClass classes={classesUnfinished} />
          </div>
        )
      }
      return (
        <WrapFinalButtons>
          {
            userCourse.data[0].cursos.certificado_automatico === 1 &&
            <>
              <Button link to='/certificados' width='200px'>Obter Certificado</Button>
              <Button link to={{
                pathname: "/avaliar-curso",
                state: { course_id: userCourse.data[0].cursos_id, course_name: userCourse.data[0].cursos.titulo }
              }} width='280px' theme={themeButtonAvaliation}>Deixe seu depoimento (opcional)</Button>
            </>
          }
        </WrapFinalButtons>
      )
    }
  }

  return null

}
export default NextButton